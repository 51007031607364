<template>
  <el-dialog
    v-model="props.afterSaleShow"
    title=""
    :before-close="handleClose"
    lock-scroll
  >
    <div class="supportStart-5">
      <div class="supportStart-5-1" style="display: flex">
        <el-select
          class="supportStart-5-1-1"
          v-model="valuelast"
          placeholder="请选择"
          @change="peopleQueryValue"
        >
          <el-option
            v-for="item in peopleQuery"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-if="peopleId == 1"
          class="supportStart-5-1-2"
          v-model="repairerListData.afterSaleOrderNumber"
          placeholder="请输入售后单号"
          size="large"
          clearable
        ></el-input>
        <el-input
          v-if="peopleId == 3"
          class="supportStart-5-1-2"
          v-model="repairerListData.masterSnCode"
          placeholder="请输入主机SN号"
          size="large"
          clearable
        ></el-input>
        <el-input
          v-if="peopleId == 2"
          class="supportStart-5-1-4"
          v-model="repairerListData.repairer"
          placeholder="请输入报修人姓名"
          size="large"
          clearable
        ></el-input>
        <el-input
          v-if="peopleId == 2"
          class="supportStart-5-1-4"
          v-model="repairerListData.repairerPhone"
          placeholder="请输入报修人联系方式"
          size="large"
          clearable
        ></el-input>
        <div class="supportStart-5-1-3" @click="handelChangeDataTwo">
          <img
            style="width: 14px; height: 14px"
            src="../../support/images/search.png"
          />
          <span class="search">搜索</span>
        </div>
      </div>
      <div v-if="SaleList" style="padding-bottom: 38px">
        <div class="content-theme" v-for="t in SaleList" :key="t.id">
          <div class="theme-1-1">
            <p class="theme-1">
              <img
                src="../../AfterSaleList/image/sh.png"
                v-if="t.status === '审核中'"
              />
              <img
                src="../../AfterSaleList/image/gb.png"
                v-if="t.status === '已拒绝'"
              />
              <img
                src="../../AfterSaleList/image/cl.png"
                v-if="t.status === '处理中'"
              />
              <img
                src="../../AfterSaleList/image/wc.png"
                v-if="t.status === '已完成'"
              />
              <span>{{ t.status }}</span>
            </p>
            <div class="theme-2">
              <span>售后单号：</span><span>{{ t.afterSaleOrderNumber }}</span>
            </div>
          </div>
          <div class="border"></div>
          <div class="btn-price">
            <div class="btn-content">
              <div class="g-1">
                <p class="g-1-1">
                  <span>主机SN号：</span
                  ><span :title="t.masterSnCode">{{ t.masterSnCode }}</span>
                </p>
                <p class="g-1-2">
                  <span>关联订单号：</span><span>{{ t.orderNumber }}</span>
                </p>
              </div>
            </div>
            <div class="btn-p-btn">
              <router-link :to="`/SaleDetail/${t.id}`">
                <p class="btn-p-btn-1">查看详情</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, reactive } from "vue";
import { repairerPhoneData } from "@/api/support";
import { ElMessage } from "element-plus";

const props = defineProps(["afterSaleShow"]);
const emit = defineEmits(["closeDialog"]);

// 关闭弹出框
const handleClose = () => {
  emit("closeDialog");
};

const valuelast = ref("按主机SN查询");

// 点击查询方式
const peopleQueryValue = (val) => {
  peopleId.value = val;
};
const peopleId = ref(3);
const peopleQuery = ref([
  {
    id: 3,
    value: "按主机SN查询",
  },
  {
    id: 1,
    value: "按售后单号查询",
  },
  {
    id: 2,
    value: "按报修联系人查询",
  },
]);

const repairerListData = reactive({
  afterSaleOrderNumber: "",
  repairer: "",
  repairerPhone: "",
  masterSnCode: "",
});

const SaleList = ref([]);
// 点击搜索
const handelChangeDataTwo = async () => {
  if (peopleId.value == 1) {
    if (repairerListData.afterSaleOrderNumber != "") {
      repairerListData.repairer = "";
      repairerListData.repairerPhone = "";
      repairerListData.masterSnCode = "";
      let res = await repairerPhoneData(repairerListData);
      try {
        if (res.code === 200) {
          if (res.data.length > 0) {
            SaleList.value = res.data;
          } else {
            ElMessage({
              message: "未查到相关订单数据",
              type: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      ElMessage({
        message: "请输入售后单号",
        type: "error",
      });
    }
  } else if (peopleId.value == 2) {
    if (
      repairerListData.repairer != "" &&
      repairerListData.repairerPhone != ""
    ) {
      repairerListData.afterSaleOrderNumber = "";
      repairerListData.masterSnCode = "";
      let res = await repairerPhoneData(repairerListData);
      try {
        if (res.code === 200) {
          if (res.data.length > 0) {
            SaleList.value = res.data;
          } else {
            ElMessage({
              message: "未查到相关订单数据",
              type: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      ElMessage({
        message: "请输入报修人姓名或联系方式",
        type: "error",
      });
    }
  } else {
    if (repairerListData.masterSnCode != "") {
      repairerListData.repairer = "";
      repairerListData.repairerPhone = "";
      repairerListData.afterSaleOrderNumber = "";
      let res = await repairerPhoneData(repairerListData);
      try {
        if (res.code === 200) {
          if (res.data.length > 0) {
            SaleList.value = res.data;
          } else {
            ElMessage({
              message: "未查到相关订单数据",
              type: "error",
            });
          }
        } else {
          ElMessage({
            message: "未查到相关订单数据",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      ElMessage({
        message: "请输入主机SN号",
        type: "error",
      });
    }
  }
};
</script>

<style scoped lang="less">
.supportStart-5 {
  background: #ffffff;
  position: relative;
	height: 600px;
  .supportStart-5-1 {
    .supportStart-5-1-1 {
      margin: 0px 0 16px 0px;
    }
    .supportStart-5-1-2 {
      width: 574px;
      height: 40px;
      background: #ffffff;
      border-radius: 5px;
      margin: 0px 0 0 12px;
    }
    .supportStart-5-1-4 {
      width: 277px;
      height: 40px;
      background: #ffffff;
      border-radius: 5px;
      margin: 0 0 0 12px;
    }
    .supportStart-5-1-3 {
      margin-left: 24px;
      width: 90px;
      height: 40px;
      line-height: 40px;
      background: #c21917;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      .search {
        width: 29px;
        height: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-left: 3px;
      }
    }
    /deep/.el-select .el-input {
      display: flex;
      height: 40px;
      background: #f2f2f2;
      width: 168px;
    }
  }
  .content-theme {
    width: 885px;
    height: 192px;
    border: 1px solid #aaaaaa;
    margin-top: 20px;
    .theme-1-1 {
      width: 885px;
      height: 60px;
      display: flex;
      .theme-1 {
        margin-left: 30px;
        margin-right: 41px;
        img {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 5px;
          margin-top: -5px;
        }
        span {
          display: inline-block;
          font-size: 18px;
          margin-top: 20px;
          font-weight: 400;
          color: #ce1200;
        }
      }
      .theme-2 {
        margin-top: 24px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .border {
    border-bottom: 1px solid #e1e1e1;
  }
  .btn-price {
    display: flex;
    justify-content: space-between;
    .btn-content {
      margin-bottom: 32px;
      margin-left: 32px;
      .g-1 {
        margin-top: 24px;
        .g-1-1 {
          width: 550px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 11px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .g-1-2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          // margin-bottom: 27px;
        }
      }
      .s-b {
        display: flex;
        justify-content: baseline;
        .s-b-1 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 27px;
          margin-right: 36px;
        }
        .s-b-2 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 27px;
          margin-right: 36px;
        }
        .s-b-3 {
          margin-top: 13px;
          margin-right: 60px;
          :nth-child(1) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          :nth-child(2) {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
        .s-b-4 {
          margin-top: 23px;
          :nth-child(1) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          :nth-child(2) {
            img {
              display: inline-block;
              width: 15px;
              height: 15px;
            }
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ce1200;
          }
        }
      }
    }
    .btn-p-btn {
      margin-right: 30px;
      .btn-p-btn-1 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-top: 20px;
        cursor: pointer;
        .hoverBorder();
      }
      .btn-p-btn-2 {
        width: 120px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #aaaaaa;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
        cursor: pointer;
        .hoverBorder();
      }
    }
  }
}
</style>
